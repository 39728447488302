<template>
<!--
  <div class="home">
-->
    <v-app>
      <v-app-bar color="brown-darken-2" prominent>
        <v-app-bar-nav-icon @click="drawer ? drawer = false : drawer = true"></v-app-bar-nav-icon>
        <v-toolbar-title v-if="language == 'ja'">
          <a href="https://72seasons.maikojapan.com/" style="text-decoration:none; color:white">
            浮世絵で見る七十二候
          </a>
        </v-toolbar-title>
        <v-toolbar-title v-if="language == 'en'">
          <a href="https://72seasons.maikojapan.com/en/" style="text-decoration:none; color:white">
            72 Seasons with Ukiyo-e
          </a>
        </v-toolbar-title>
        <v-toolbar-title v-if="language == 'fr'">
          <a href="https://72seasons.maikojapan.com/fr/" style="text-decoration:none; color:white">
            72 Saisons du Japon
          </a>
        </v-toolbar-title>

        <!-- 言語設定 -->
        <v-menu>
          <template v-slot:activator="{ props }">
            <v-btn icon="$translate" v-bind="props"></v-btn>
          </template>
          <v-list>
            <v-list-item @click="setLanguage('ja')">
              <v-list-item-title>日本語</v-list-item-title>
            </v-list-item>
            <v-list-item @click="setLanguage('en')">
              <v-list-item-title>English</v-list-item-title>
            </v-list-item>
            <v-list-item @click="setLanguage('fr')">
              <v-list-item-title>Français</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-app-bar>

      <!-- サイドメニュー -->
      <v-navigation-drawer v-model="drawer" absolute temporary style="position:fixed;">
        <!-- 日本語メニュー -->
        <v-list v-if="language == 'ja'" nav dense>
          <v-list-item class="font-weight-bold" href="/">
            <v-icon icon="$home" class="mr-1" />Home
          </v-list-item>
          <v-list-item class="font-weight-bold" href="/what-is-72-seasons/">
            <v-icon icon="$book" class="mr-1" />七十二候とは
          </v-list-item>
          <v-list-item class="font-weight-bold" href="/season-now/">
            <v-icon icon="$weather" class="mr-1" />今日は何の季節？
          </v-list-item>
          <v-list-item class="font-weight-bold" href="/search-by-name/">
            <v-icon icon="$magnify" class="mr-1" />名前から探す七十二候
          </v-list-item>
          <v-list-item class="font-weight-bold" href="/search-by-date/">
            <v-icon icon="$magnify" class="mr-1" />日にちから探す七十二候
          </v-list-item>
          <v-list-item class="font-weight-bold" href="/search-by-ukiyoe/">
            <v-icon icon="$magnify" class="mr-1" />浮世絵から探す七十二候
          </v-list-item>
        </v-list>

        <!-- 英語メニュー -->
        <v-list v-if="language == 'en'" nav dense>
          <v-list-item class="font-weight-bold" href="/en/">
            <v-icon icon="$home" class="mr-1" />Home
          </v-list-item>
          <v-list-item class="font-weight-bold" href="/en/what-is-72-seasons/">
            <v-icon icon="$book" class="mr-1" />What Is 72 Seasons?
          </v-list-item>
          <v-list-item class="font-weight-bold" href="/en/season-now/">
            <v-icon icon="$weather" class="mr-1" />Today's Season
          </v-list-item>
          <v-list-item class="font-weight-bold" href="/en/search-by-name/">
            <v-icon icon="$magnify" class="mr-1" />Search By Name
          </v-list-item>
          <v-list-item class="font-weight-bold" href="/en/search-by-date/">
            <v-icon icon="$magnify" class="mr-1" />Search By Date
          </v-list-item>
          <v-list-item class="font-weight-bold" href="/en/search-by-ukiyoe/">
            <v-icon icon="$magnify" class="mr-1" />Search By Ukiyo-e
          </v-list-item>
        </v-list>

        <!-- フランス語メニュー -->
        <v-list v-if="language == 'fr'" nav dense>
          <v-list-item class="font-weight-bold" href="/fr/">
            <v-icon icon="$home" class="mr-1" />Accueil
          </v-list-item>
          <v-list-item class="font-weight-bold" href="/fr/what-is-72-seasons/">
            <v-icon icon="$book" class="mr-1" />Pourquoi 72 Saisons ?
          </v-list-item>
          <v-list-item class="font-weight-bold" href="/fr/season-now/">
            <v-icon icon="$weather" class="mr-1" />Saison d'aujourd'hui
          </v-list-item>
          <v-list-item class="font-weight-bold" href="/fr/search-by-name/">
            <v-icon icon="$magnify" class="mr-1" />Rechercher par nom
          </v-list-item>
          <v-list-item class="font-weight-bold" href="/fr/search-by-date/">
            <v-icon icon="$magnify" class="mr-1" />Rechercher par date
          </v-list-item>
          <v-list-item class="font-weight-bold" href="/fr/search-by-ukiyoe/">
            <v-icon icon="$magnify" class="mr-1" />Rechercher par estampe
          </v-list-item>
        </v-list>
      </v-navigation-drawer>

      <v-main>
        <v-row no-gutters justify="center">
          <v-col>
            <router-view />
          </v-col>
        </v-row>

        <v-row no-gutters justify="center">
          <!-- Google Adsense BOTTOM -->
          <div id="adsBottom" style="width:1000px; max-width:100%;" v-html="adsContentBottom"></div>
        </v-row>
      </v-main>

      <!-- フッター -->
      <v-footer color="brown-darken-2" class="background">
        <v-row justify="center" no-gutters>
          <template v-if="language == 'ja'">
            <v-btn
              v-for="link in linksJP"
              :key="link"
              color="brown-darken-2"
              variant="text"
              rounded="xl"
              :href="link.url"
              target="_blank"
              class="no-uppercase"
            >
              {{ link.urlname }}
              <v-icon icon="$openNew" class="ml-1" />
            </v-btn>
          </template>
          <template v-if="language == 'en'">
            <v-btn
              v-for="link in linksEN"
              :key="link"
              color="brown-darken-2"
              variant="text"
              rounded="xl"
              :href="link.url"
              target="_blank"
              class="no-uppercase"
            >
              {{ link.urlname }}
              <v-icon icon="$openNew" class="ml-1" />
            </v-btn>
          </template>
          <template v-if="language == 'fr'">
            <v-btn
              v-for="link in linksFR"
              :key="link"
              color="brown-darken-2"
              variant="text"
              rounded="xl"
              :href="link.url"
              target="_blank"
              class="no-uppercase"
            >
              {{ link.urlname }}
              <v-icon icon="$openNew" class="ml-1" />
            </v-btn>
          </template>

          <v-col class="text-center my-1" cols="12">
            <template v-if="language == 'ja'">
              <v-btn
                v-for="link in siteInfo"
                :key="link"
                color="brown-darken-2"
                variant="text"
                rounded="xl"
                :href="link.url"
                class="no-uppercase"
              >
              {{ link.urlname }}
              </v-btn>
            </template>
            <template v-if="language == 'en'">
              <v-btn
                v-for="link in siteInfoEN"
                :key="link"
                color="brown-darken-2"
                variant="text"
                rounded="xl"
                :href="link.url"
                class="no-uppercase"
              >
              {{ link.urlname }}
              </v-btn>
            </template>
            <template v-if="language == 'fr'">
              <v-btn
                v-for="link in siteInfoFR"
                :key="link"
                color="brown-darken-2"
                variant="text"
                rounded="xl"
                :href="link.url"
                class="no-uppercase"
              >
              {{ link.urlname }}
              </v-btn>
            </template>
          </v-col>

          <v-col class="text-center my-2" cols="12">
            <div style="color:#5D4037">
              Copyright © {{ new Date().getFullYear() }}
              <a href="https://maikojapan.com/" class="text-decoration-none" style="color:#5D4037" target="_blank">
                Maiko Japan
              </a>
            </div>
          </v-col>
        </v-row>
      </v-footer>
    </v-app>
<!--
  </div>
-->
</template>

<script>
export default {
  name: 'app',
  data: () => ({
    drawer: false,
//    adsContentTop: '',
    adsContentBottom: '',

    linksJP: [
      {
        urlname: "各地の年間天気グラフ",
        url: "https://weather.monsieurlangue.com/"
      },
      {
        urlname: "にほんごの書き順",
        url: "https://nihongo.maikojapan.com/jp/"
      },
      {
        urlname: "フランス語クイズ",
        url: "https://quiz.monsieurlangue.com/"
      }
    ],
    linksEN: [
      {
        urlname: "Nihongo Stroke Order",
        url: "https://nihongo.maikojapan.com/"
      },
      {
        urlname: "Kanji Flashcards & Free Study Sheets",
        url: "https://maikojapan.com/"
      }
    ],
    linksFR: [
      {
        urlname: "TekuTeku Japan",
        url: "https://www.tekuteku-japan.com/"
      },
      {
        urlname: "Nihongo Stroke Order",
        url: "https://nihongo.maikojapan.com/fr/"
      },
      {
        urlname: "Kanji Flashcards & Free Study Sheets",
        url: "https://maikojapan.com/"
      }
    ],

    siteInfo: [
      {
        urlname: "利用規約",
        url: "https://72seasons.maikojapan.com/privacy_policy/"
      }
    ],
    siteInfoEN: [
      {
        urlname: "Privacy & Terms",
        url: "https://72seasons.maikojapan.com/en/privacy_policy/"
      }
    ],
    siteInfoFR: [
      {
        urlname: "Politique de confidentialité",
        url: "https://72seasons.maikojapan.com/fr/privacy_policy/"
      }
    ],

  }),
  methods: {
    setLanguage (language) {
      this.$store.commit('setLanguage', language)
      var currentLocation = window.location.pathname
      currentLocation = currentLocation.replace("/en/", "/")
      currentLocation = currentLocation.replace("/fr/", "/")
      if (language == "ja") {
        window.location.href = currentLocation
      } else if (language == "en") {
        window.location.href = '/en' + currentLocation
      } else if (language == "fr") {
        window.location.href = '/fr' + currentLocation
      }
    }
  },
  computed: {
    language: {
      get(){
        return this.$store.state.language
      },
      set(value){
        this.$store.commit('setLanguage', value)
      }
    },
  },
  mounted() {
/*
    if (document.getElementById('divadsenseTop')) {
      this.adsContentTop = document.getElementById('divadsenseTop').innerHTML
    }
*/
    if (document.getElementById('divadsenseBottom')) {
      this.adsContentBottom = document.getElementById('divadsenseBottom').innerHTML
    }

    if (window.location.pathname.includes("/en/")) {
      this.$store.commit('setLanguage', 'en')
    } else if (window.location.pathname.includes("/fr/")) {
      this.$store.commit('setLanguage', 'fr')
    } else {
      this.$store.commit('setLanguage', 'ja')
    }
  }
}
</script>

<style>
.background {
  background-image: url("./assets/washi.jpg");
  background-repeat: repeat;
}
.no-uppercase {
     text-transform: initial !important;
}
</style>
